import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TfaCodeModal from './TfaCodeModal';
import Button from 'components/Button/Button';
import PhoneIcon from 'assets/icons/PhoneIcon';
import EmailIcon from 'assets/icons/EmailIcon';
import GoogleAuthenticatorIcon from 'assets/icons/GoogleAuthenticatorIcon';
import CompleteAuthenticationIcon from 'assets/icons/CompleteAuthenticationIcon';
import useAuth from 'hooks/fetch/useAuth';
import { useSelector } from 'react-redux';
import useSettings from 'hooks/fetch/useSettings';
import ManualSetUp from './ManualSetUp';

const SecurityModal = ({ close }) => {
  const [step, setStep] = useState(1);
  const nextStep = () => setStep((prev) => prev + 1);
  const jumpStep = (data) => setStep(data);
  const [type, setType] = useState(null);
  const [twoFactorAuthenticationQrCode, setTwoFactorAuthenticationQrCode] = useState(null);

  const handleModalSteps = () => {
    switch (step) {
      case 1:
        return <ModalStep1 nextStep={nextStep} />;
      case 2:
        return (
          <ModalStep2
            nextStep={nextStep}
            jumpStep={jumpStep}
            setType={setType}
            setTwoFactorAuthenticationQrCode={setTwoFactorAuthenticationQrCode}
          />
        );
      case 3:
        return <ModalStep3 nextStep={nextStep} />;
      case 4:
        return (
          <ModalStep4
            jumpStep={jumpStep}
            nextStep={nextStep}
            twoFactorAuthenticationQrCode={twoFactorAuthenticationQrCode}
          />
        );
      case 5:
        return <TfaCodeModal nextStep={nextStep} close={close} type={type} />;
      case 6:
        return <ModalStep6 nextStep={close} />;
      case 7:
        return (
          <ManualSetUp onClick={() => jumpStep(5)} twoFactorAuthenticationQrCode={twoFactorAuthenticationQrCode} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-[#00000050] z-20 h-full w-full flex items-center justify-center flex-col gap-5">
      <div className="max-w-[464px] w-[95%] bg-white rounded-xl p-20 lg:p-48 flex flex-col gap-6">
        {handleModalSteps()}
      </div>
    </div>
  );
};

export default SecurityModal;

const ModalStep1 = ({ nextStep }) => {
  return (
    <div className="flex flex-col w-full gap-[30px]">
      <div className="flex flex-col w-full gap-4">
        <p className="text-[24px] leading-8 font-bold text-dark_blue font-dmsans_b">
          2FA has not been set on this account
        </p>
        <p className="text-16 leading-6 text-grey font-dmsans_r">
          Please setup 2FA for optimal security of your account.{' '}
        </p>
      </div>
      <Button styles="ml-auto px-6 py-2 w-fit !rounded-[4px]" onClick={nextStep}>
        Set Up 2FA
      </Button>
    </div>
  );
};

const ModalStep2 = ({ jumpStep, nextStep, setType, setTwoFactorAuthenticationQrCode }) => {
  const { loggedUser } = useSelector((state) => state.auth);
  const { loading, resend_verification_otp } = useAuth();
  const { loading: loadingSettings, generate_google_auth_token } = useSettings();

  const selectEmail = async () => {
    const res = await resend_verification_otp({ email: loggedUser?.email });
    if (res?.code === 200) {
      setType('email');
      jumpStep(5);
    }
  };

  const selectGoogleAuthenticator = async () => {
    const res = await generate_google_auth_token();
    if (res?.code === 200) {
      setTwoFactorAuthenticationQrCode(res?.data);
      setType('authenticator');
      nextStep();
    }
  };

  return (
    <div className="flex flex-col w-full gap-[30px]">
      <div className="flex flex-col w-full gap-2">
        <p className="text-[24px] leading-8 font-bold text-dark_blue font-dmsans_b">Two Factor Authentication</p>
        <p className="text-16 leading-6 text-grey font-dmsans_r">
          How would you like to receive your authentication code?
        </p>
      </div>
      <div className="flex w-full flex-col gap-4">
        <button
          type="button"
          disabled={loading || loadingSettings}
          onClick={selectEmail}
          className="p-6 rounded-[4px] flex items-start justify-center gap-4 bg-purple_transparent hover:bg-purple-50 focus:bg-purple-50 transition-all"
        >
          <EmailIcon />
          <div className="flex flex-col flex-1 gap-1 items-start text-left">
            <p className="text-16 leading-6 font-dmsans_m text-dark_blue">Email</p>
            <p className="text-12 leading-[18px] font-dmsans_r text-grey">
              Receive an OTP in your email whenever you sign in
            </p>
          </div>
        </button>
        <button
          type="button"
          disabled={loading || loadingSettings}
          onClick={selectGoogleAuthenticator}
          className="p-6 rounded-[4px] flex items-start gap-4 bg-purple_transparent hover:bg-purple-50 focus:bg-purple-50 transition-all"
        >
          <PhoneIcon />
          <div className="flex flex-col flex-1 gap-1 items-start text-left">
            <p className="text-16 leading-6 font-dmsans_m text-dark_blue">Authenticator App</p>
            <p className="text-12 leading-[18px] font-dmsans_r text-grey">
              Get authentication code via Google Authenticator
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

const ModalStep3 = ({ nextStep }) => {
  const [downloadURL, setDownloadURL] = useState('');

  useEffect(() => {
    if (/Android/i.test(window.navigator.userAgent)) {
      setDownloadURL('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2');
    }

    if (/iPhone|iPad/i.test(window.navigator.userAgent)) {
      setDownloadURL('itms-apps://apps.apple.com/US/app/id388497605');
    }

    setDownloadURL('https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en');
  }, [window.navigator.userAgent]);

  return (
    <div className="flex flex-col w-full gap-9">
      <div className="flex flex-col w-full gap-[30px] items-center text-center">
        <div className="w-[200px] h-[200px] rounded-full bg-icon-bg/30 flex items-center justify-center">
          <GoogleAuthenticatorIcon />
        </div>
        <p className="text-[24px] leading-8 font-bold text-dark_blue font-dmsans_b">Setup Google authenticator</p>
        <p className="text-16 leading-6 text-completed-text font-dmsans_r">
          Download google authenticator to setup two-factor authentication.
        </p>
      </div>
      <div className="flex w-full flex-col gap-5">
        <a href={downloadURL} target="blank" className="w-full">
          <Button type="button" styles="w-full">
            Download Google authenticator
          </Button>
        </a>
        <button
          type="button"
          onClick={nextStep}
          className="border border-purple bg-transparent font-dmsans_m text-purple h-12 transition-all rounded-lg hover:text-white hover:bg-purple focus:bg-purple focus:text-white"
        >
          Downloaded Already
        </button>
      </div>
    </div>
  );
};

const ModalStep4 = ({ jumpStep, nextStep, twoFactorAuthenticationQrCode }) => {
  return (
    <div className="flex flex-col w-full gap-9">
      <div className="flex flex-col w-full gap-[30px] items-center text-center">
        <div className="w-[200px] h-[200px] rounded-full bg-icon-bg flex items-center justify-center">
          <img
            src={twoFactorAuthenticationQrCode?.qrCodeUrl}
            className="rounded-[21px] w-[112.87px] h-[112.87px] bg-white object-cover"
          />
        </div>
        <p className="text-[24px] leading-8 font-bold text-dark_blue font-dmsans_b">Scan QR code</p>
        <p className="text-16 leading-6 text-completed-text font-dmsans_r">
          From your device, launch Google Authenticator, and scan the QRCode above. Select completed, when you’ve added
          the key above successfully.
        </p>
      </div>
      <div className="flex w-full items-center justify-between gap-[38px]">
        <button type="button" onClick={() => jumpStep(7)} className="text-purple flex-1 font-dmsans_m">
          Setup manually
        </button>
        <Button type="button" onClick={() => jumpStep(5)} styles="flex-1">
          Completed
        </Button>
      </div>
    </div>
  );
};

const ModalStep6 = ({ nextStep }) => {
  return (
    <div className="flex flex-col items-center justify-center text-center w-full gap-9">
      <CompleteAuthenticationIcon />
      <p className="text-14 w-[85%] mx-auto leading-5 text-completed-text font-dmsans_r">
        When prompted for a 2FA code, copy the code generated in your authenticator to access your account.
      </p>
      <Button type="button" onClick={nextStep} styles="flex-1 w-full">
        Completed
      </Button>
    </div>
  );
};

ModalStep1.propTypes = {
  nextStep: PropTypes.func,
};

ModalStep2.propTypes = {
  jumpStep: PropTypes.func,
  nextStep: PropTypes.func,
  setType: PropTypes.func,
  setTwoFactorAuthenticationQrCode: PropTypes.func,
};

ModalStep3.propTypes = {
  nextStep: PropTypes.func,
};

ModalStep4.propTypes = {
  jumpStep: PropTypes.func,
  nextStep: PropTypes.func,
  twoFactorAuthenticationQrCode: PropTypes.any,
};

ModalStep6.propTypes = {
  jumpStep: PropTypes.func,
  nextStep: PropTypes.func,
};

SecurityModal.propTypes = {
  close: PropTypes.func,
};
