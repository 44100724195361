import useValidateFormData from '../useValidateFormData';
import useHandleError from '../useHandleError';
import { useAuthState } from '../../store/modules/auth';
import { notify } from '../../store/modules/global';
import { useAppDispatch } from '../useAppDispatch';
import useMakeRequest from '../useMakeRequest';
// Actions
import {
  login,
  forgotPassword,
  resetPassword,
  inviteAdmin,
  editAdmin,
  validatePasswordResetToken,
  createPassword,
  changePassword,
  assignRole,
  editRoleDetails,
  resendVerificationOtp,
  validate2fa,
} from '../../store/modules/auth/actions';
import { useState } from 'react';
import useTryCatch from 'hooks/useTryCatch';

export default function useAuth() {
  const authState = useAuthState();
  const { recoverUser, reset_code } = authState;
  const [loading, setLoading] = useState(false);
  const { makeRequest } = useMakeRequest();
  const dispatch = useAppDispatch();
  const { validateFormData } = useValidateFormData();
  const { handleError } = useHandleError();
  const { tryCatch } = useTryCatch();

  const signIn = (formData) => {
    return tryCatch(async () => {
      let enhancedFormData = formData;

      Object.keys(enhancedFormData).forEach((key) => {
        if (enhancedFormData[key] === '' || key === 'confirm_password') {
          delete enhancedFormData[key];
        }
      });
      if (!validateFormData(enhancedFormData)) return; // check if formData is valid
      setLoading(true);
      return makeRequest({ action: login(enhancedFormData), alert: false }).finally(() => setLoading(false));
    });
  };

  const invite_admin = async (formData, callback) => {
    try {
      makeRequest({
        action: inviteAdmin(formData),
        callback,
      });
    } catch {
      callback;
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  const edit_admin = async (formData, id, callback, backClick) => {
    try {
      let enhancedFormData = formData;

      // filter undefined form values
      Object.keys(enhancedFormData).forEach((key) => {
        if (enhancedFormData[key] === '' || key === 'email') {
          delete enhancedFormData[key];
        }
      });

      const payload = { id, enhancedFormData };
      makeRequest({ action: editAdmin(payload), callback });
      backClick();
    } catch (error) {
      backClick();
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  const forgot_password = async (formData) => {
    const { email } = formData;

    try {
      if (!validateFormData(formData)) return; // check if formData is valid
      makeRequest({ action: forgotPassword(formData), to: `/verify-account?email=${email}` });
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  const resend_forgot_password_otp = async (formData) => {
    try {
      makeRequest({ action: forgotPassword(formData) });
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  const reset_password = (formData) => {
    return tryCatch(async () => {
      let enhancedFormData = formData;

      // filter undefined form values
      Object.keys(enhancedFormData).forEach((key) => {
        if (enhancedFormData[key] === '' || key === 'confirm_password') {
          delete enhancedFormData[key];
        }
      });

      if (!validateFormData(formData)) return; // check if formData is valid
      makeRequest({
        action: resetPassword({ ...enhancedFormData, email: recoverUser?.email, reset_code }),
        to: '/login',
      });
    });
  };

  const change_password = (formData) => {
    return tryCatch(async () => {
      let enhancedFormData = formData;

      // filter undefined form values
      Object.keys(enhancedFormData).forEach((key) => {
        if (enhancedFormData[key] === '' || key === 'confirm_new_password') {
          delete enhancedFormData[key];
        }
      });

      if (!validateFormData(formData)) return; // check if formData is valid
      makeRequest({ action: changePassword(enhancedFormData) });
    });
  };

  const create_password = (formData, email) => {
    return tryCatch(async () => {
      let enhancedFormData = formData;

      // filter undefined form values
      Object.keys(enhancedFormData).forEach((key) => {
        if (enhancedFormData[key] === '' || key === 'confirm_password') {
          delete enhancedFormData[key];
        }
      });

      if (!validateFormData(formData)) return; // check if formData is valid
      makeRequest({ action: createPassword({ ...enhancedFormData, email: email }), to: '/login?invited=true' });
    });
  };

  const verifyaccount = ({ otp, email }) => {
    return tryCatch(async () => {
      let tokenArray = otp.map((item) => item.value);
      let reset_code = tokenArray.join('').toString();
      makeRequest({
        action: validatePasswordResetToken({
          email,
          reset_code,
        }),
        to: '/reset-password',
      });
    });
  };

  const createRole = async (formData) => {
    makeRequest({ action: assignRole(formData), to: '/settings/roles-and-permissions' });
  };

  const editRole = (role, formData) => {
    return tryCatch(async () => {
      const payload = {
        role,
        formData,
      };
      makeRequest({ action: editRoleDetails(payload), to: '/settings/roles-and-permissions' });
    });
  };

  const resend_verification_otp = async (formData) => {
    try {
      setLoading(true);
      return makeRequest({ action: resendVerificationOtp(formData) }).finally(() => setLoading(false));
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  const validate_2fa = async ({ otp: code, email, password }) => {
    try {
      let tokenArray = code.map((item) => item.value);
      let otp = tokenArray.join('').toString();
      setLoading(true);
      return makeRequest({ action: validate2fa({ otp, email, password }) }).finally(() => setLoading(false));
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  return {
    signIn,
    forgot_password,
    resend_forgot_password_otp,
    reset_password,
    create_password,
    verifyaccount,
    createRole,
    editRole,
    invite_admin,
    edit_admin,
    change_password,
    resend_verification_otp,
    validate_2fa,
    loading,
  };
}
