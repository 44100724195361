import React from 'react';

export default function CompleteAuthenticationIcon () {
  return (
    <svg width="160" height="194" viewBox="0 0 160 194" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="36.5" width="87.2462" height="188.354" rx="14.6769" fill="#D3BCFF" />
      <rect x="40.5781" y="4.07812" width="79.0923" height="180.2" rx="12.2308" fill="white" />
      <rect x="68.3008" y="8.15234" width="22.8308" height="6.52308" rx="3.26154" fill="#D3BCFF" />
      <rect x="0.5" y="39.7383" width="159" height="63.6" rx="7.33846" fill="#E9E6FD" />
      <path
        d="M24.1521 60.4971C24.2649 59.5367 25.6589 59.5367 25.7717 60.4971L26.6724 68.1662C26.7279 68.6391 27.1751 68.964 27.642 68.8707L35.2141 67.3574C36.1623 67.1679 36.5931 68.4937 35.7146 68.8978L28.6991 72.1242C28.2666 72.3232 28.0958 72.8489 28.3288 73.2641L32.1079 79.9979C32.5812 80.8412 31.4534 81.6606 30.7976 80.9499L25.5612 75.2749C25.2383 74.925 24.6855 74.925 24.3626 75.2749L19.1262 80.9499C18.4704 81.6606 17.3426 80.8412 17.8159 79.9979L21.595 73.2641C21.828 72.8489 21.6572 72.3232 21.2247 72.1242L14.2092 68.8978C13.3307 68.4937 13.7615 67.1679 14.7097 67.3574L22.2818 68.8707C22.7487 68.964 23.1959 68.6391 23.2514 68.1662L24.1521 60.4971Z"
        fill="#6463FD"
      />
      <path
        d="M60.8421 61.5645C60.9416 60.5875 62.365 60.5875 62.4645 61.5645L63.1636 68.4274C63.2129 68.911 63.6717 69.2444 64.1469 69.1418L70.8899 67.6859C71.8499 67.4787 72.2897 68.8323 71.3913 69.2289L65.0803 72.0146C64.6356 72.2109 64.4603 72.7502 64.7047 73.1705L68.173 79.1336C68.6668 79.9825 67.5153 80.8191 66.8605 80.0872L62.261 74.9459C61.9369 74.5836 61.3697 74.5836 61.0456 74.9459L56.4461 80.0872C55.7913 80.8191 54.6398 79.9825 55.1336 79.1336L58.6019 73.1705C58.8463 72.7502 58.671 72.2109 58.2263 72.0146L51.9153 69.2289C51.0169 68.8323 51.4567 67.4787 52.4167 67.6859L59.1597 69.1418C59.6349 69.2444 60.0937 68.911 60.143 68.4274L60.8421 61.5645Z"
        fill="#6463FD"
      />
      <path
        d="M97.5335 61.5645C97.6331 60.5875 99.0564 60.5875 99.1559 61.5645L99.855 68.4274C99.9043 68.911 100.363 69.2444 100.838 69.1418L107.581 67.6859C108.541 67.4787 108.981 68.8323 108.083 69.2289L101.772 72.0146C101.327 72.2109 101.152 72.7502 101.396 73.1705L104.864 79.1336C105.358 79.9825 104.207 80.8191 103.552 80.0872L98.9524 74.9459C98.6283 74.5836 98.0611 74.5836 97.737 74.9459L93.1375 80.0872C92.4827 80.8191 91.3312 79.9825 91.825 79.1336L95.2933 73.1705C95.5377 72.7502 95.3624 72.2109 94.9177 72.0146L88.6067 69.2289C87.7083 68.8323 88.1481 67.4787 89.1081 67.6859L95.8511 69.1418C96.3263 69.2444 96.7851 68.911 96.8344 68.4274L97.5335 61.5645Z"
        fill="#6463FD"
      />
      <path
        d="M134.229 61.5645C134.328 60.5875 135.752 60.5875 135.851 61.5645L136.55 68.4274C136.6 68.911 137.058 69.2444 137.534 69.1418L144.277 67.6859C145.237 67.4787 145.676 68.8323 144.778 69.2289L138.467 72.0146C138.022 72.2109 137.847 72.7502 138.091 73.1705L141.56 79.1336C142.054 79.9825 140.902 80.8191 140.247 80.0872L135.648 74.9459C135.324 74.5836 134.756 74.5836 134.432 74.9459L129.833 80.0872C129.178 80.8191 128.027 79.9825 128.52 79.1336L131.989 73.1705C132.233 72.7502 132.058 72.2109 131.613 72.0146L125.302 69.2289C124.404 68.8323 124.843 67.4787 125.803 67.6859L132.546 69.1418C133.022 69.2444 133.48 68.911 133.53 68.4274L134.229 61.5645Z"
        fill="#6463FD"
      />
      <circle cx="117.5" cy="172" r="22" fill="#5034C4" />
      <path
        d="M126.446 167.171C126.446 167.767 126.221 168.342 125.812 168.752L116.297 178.319C115.867 178.73 115.294 178.976 114.7 178.976C114.107 178.976 113.534 178.73 113.125 178.319L108.357 173.536C107.948 173.125 107.723 172.55 107.723 171.955C107.723 171.359 107.968 170.805 108.377 170.374C108.807 169.963 109.36 169.738 109.953 169.717C110.547 169.717 111.099 169.943 111.529 170.354L114.7 173.536L122.64 165.57C123.07 165.159 123.622 164.934 124.216 164.934C124.809 164.954 125.362 165.18 125.791 165.611C126.201 166.022 126.446 166.576 126.446 167.171Z"
        fill="white"
      />
    </svg>
  );
}
