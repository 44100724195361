import React from 'react';
import DashBoardIcon from '../assets/icons/DashBoardIcon';
import TransferIcon from '../assets/icons/TransferIcon';
import PaymentIcon from '../assets/icons/PaymentIcon';
import ScheduleIcon from '../assets/icons/ScheduleIcon';
import VirtualCardIcon from '../assets/icons/VirtualCardIcon';
import VoucherIcon from '../assets/icons/VoucherIcon';
import UserIcon from '../assets/icons/UserIcon';
import SettingIcon from '../assets/icons/SettingIcon';
import DocumentIcon from '../assets/icons/DocumentIcon';
import HomeIcon from '../assets/icons/HomeIcon';
import CustomerIcon from '../assets/icons/CustomerIcon';
import { useLocation } from 'react-router-dom';
import usePermissions from 'hooks/usePermissions';
import { useAuthState } from 'store/modules/auth';
import { KycIcon } from 'assets/icons/KycIcon';
import TransactionHistoryIcon from 'assets/icons/TransactionHistoryIcon';
import { AdminTransactionIcon } from 'assets/icons/AdminTransactionIcon';

export const SideBarLinks = () => {
  const { pathname } = useLocation();
  const { userIsPermittedTo } = usePermissions();
  const {
    loggedUser: { role },
  } = useAuthState();

  const top_nav_links = [
    {
      id: 1,
      title: 'Get Started',
      icon: <HomeIcon color={`${pathname.includes('get-started') ? '#5034C4' : '#3A434B'}`} />,
      url: 'get-started',
      value: 'get_started',
      display: role === 'Super-Admin',
    },
    {
      id: 2,
      title: 'Dashboard',
      icon: <DashBoardIcon color={`${pathname.includes('home') ? '#5034C4' : '#3A434B'}`} />,
      url: '/home',
      value: 'dashboard_management',
      display: userIsPermittedTo('Read', 'dashboard_management'),
    },
    {
      id: 3,
      title: 'Transfers',
      icon: <TransferIcon color={`${pathname.includes('transfers') ? '#5034C4' : '#3A434B'}`} />,
      url: '/transfers',
      value: 'transfers_management',
      display: userIsPermittedTo('Read', 'transfers_management'),
    },
    {
      id: 4,
      title: 'Payments',
      icon: <PaymentIcon color={`${pathname === '/payments' ? '#5034C4' : '#3A434B'}`} />,
      url: '/payments',
      value: 'payments_management',
      display: userIsPermittedTo('Read', 'payments_management'),
    },
    {
      id: 4,
      title: 'Guest Payments',
      icon: <PaymentIcon color={`${pathname === '/guest-payments' ? '#5034C4' : '#3A434B'}`} />,
      url: '/guest-payments',
      value: 'payments_management',
      display: userIsPermittedTo('Read', 'payments_management'),
    },
    {
      id: 5,
      title: 'Schedule & Automated',
      icon: <ScheduleIcon color={`${pathname.includes('schedule-and-automation') ? '#5034C4' : '#3A434B'}`} />,
      url: 'schedule-and-automation',
      value: 'scheduled_payments_management',
      display: userIsPermittedTo('Read', 'scheduled_payments_management'),
    },
    {
      id: 6,
      title: 'Virtual Cards',
      icon: <VirtualCardIcon color={`${pathname.includes('virtual-card-management') ? '#5034C4' : '#3A434B'}`} />,
      url: '/virtual-card-management',
      value: 'virtual_cards_management',
      display: userIsPermittedTo('Read', 'virtual_cards_management'),
    },
    {
      id: 7,
      title: 'Vouchers',
      icon: <VoucherIcon color={`${pathname.includes('vouchers') ? '#5034C4' : '#3A434B'}`} />,
      url: '/vouchers',
      value: 'vouchers_management',
      display: userIsPermittedTo('Read', 'vouchers_management'),
    },
    {
      id: 8,
      title: 'Manage Admin',
      icon: <UserIcon color={`${pathname.includes('manage-admin') ? '#5034C4' : '#3A434B'}`} />,
      url: '/manage-admin',
      value: 'admin_management',
      display: userIsPermittedTo('Read', 'admin_management'),
    },
    {
      id: 9,
      title: 'KYC Management',
      icon: <KycIcon color={`${pathname.includes('kyc-management') ? '#5034C4' : '#3A434B'}`} />,
      url: '/kyc-management',
      value: 'customer_management',
      display: userIsPermittedTo('Read', 'customer_management'),
    },
    {
      id: 9,
      title: 'Manage Customer',
      icon: <CustomerIcon color={`${pathname.includes('manage-customer') ? '#5034C4' : '#3A434B'}`} />,
      url: '/manage-customer',
      value: 'customer_management',
      display: userIsPermittedTo('Read', 'customer_management'),
    },
    {
      id: 11,
      title: 'Audit Logs',
      icon: <DocumentIcon color={`${pathname.includes('audit-logs') ? '#5034C4' : '#3A434B'}`} />,
      url: '/audit-logs',
      value: 'audit_trail_management',
      display: userIsPermittedTo('Read', 'audit_trail_management'),
    },
    {
      id: 12,
      title: 'Admin Transactions',
      icon: <AdminTransactionIcon color={`${pathname.includes('transaction-history') ? '#5034C4' : '#3A434B'}`} />,
      url: '/transaction-history',
      value: 'transaction_history',
      display: role === 'Super-Admin',
    },
    {
      id: 12,
      title: 'Transactions',
      icon: <TransactionHistoryIcon color={`${pathname.includes('transaction-history') ? '#5034C4' : '#3A434B'}`} />,
      url: '/customer-transactions',
      value: 'customer_transactions',
      display: role === 'Super-Admin',
    },
  ];

  const middle_nav_links = [
    {
      id: 1,
      title: 'Settings',
      icon: <SettingIcon color={`${pathname.includes('settings') ? '#5034C4' : '#3A434B'}`} />,
      display:
        userIsPermittedTo('Read', 'site_settings_management') ||
        userIsPermittedTo('Read', 'notifications_management') ||
        userIsPermittedTo('Read', 'discount_management') ||
        userIsPermittedTo('Read', 'admin_management'),
      subMenuItems: [
        {
          id: 1,
          name: 'Site Settings',
          route: 'settings/site-settings',
          value: 'site_settings_management',
          display: userIsPermittedTo('Read', 'site_settings_management'),
        },
        {
          id: 2,
          name: 'Notification',
          route: 'settings/notifications',
          value: 'notifications_management',
          display: userIsPermittedTo('Read', 'notifications_management'),
        },
        {
          id: 3,
          name: 'Discount Management',
          route: 'settings/discount-management',
          value: 'discount_management',
          display: userIsPermittedTo('Read', 'discount_management'),
        },
        {
          id: 4,
          name: 'Roles & Permission',
          route: 'settings/roles-and-permissions',
          value: 'admin_management',
          display: userIsPermittedTo('Read', 'admin_management'),
        },
        {
          id: 4,
          name: 'Security',
          route: 'settings/security',
          value: 'security',
          display: true
        },
      ],
    },
    // {
    //   id: 2,
    //   title: 'Contact Support',
    //   icon: <Contact color={`${pathname.includes('contact-support') ? '#5034C4' : '#3A434B'}`} />,
    //   url: '/contact-support',
    //   value: 'contact_support',
    //   display: true,
    // },
  ];

  return { top_nav_links, middle_nav_links };
};
