import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Logo from '../../assets/icons/Logo';
import BackButtonIcon from '../../assets/icons/BackButtonIcon';
import Header from '../Header/Header';
import useAuth from '../../hooks/fetch/useAuth';
import { useAuthState } from '../../store/modules/auth';

export default function ResetPasswordForm() {
  //Local State
  const [formData, setFormData] = useState({
    reset_code: '',
    password: '',
    confirm_password: '',
  });

  //Hooks
  const authState = useAuthState();
  const { loading } = authState;
  const { reset_password } = useAuth();

  const makeRequest = (e) => {
    e.preventDefault();

    reset_password(formData);
  };
  return (
    <section className="w-full md:px-40 md:py-48">
      <div className="lg:hidden block mb-48">
        <Logo />
      </div>
      <div className="w-max">
        <div className="flex items-center lg:ml-136 md:mt-76 font-dmsans_r font-normal dark_blue">
          <Link to="/login">
            <div className="rounded-full bg-smoke py-3 px-3">
              <BackButtonIcon />
            </div>
          </Link>
          <p className="text-base ml-4">Back to Login</p>
        </div>
      </div>
      <div className="w-full max-w-md mx-auto mt-10 lg:mt-0">
        <div className="lg:flex hidden mb-48 justify-center">
          <Logo />
        </div>
        <form className="transition_left" onSubmit={(e) => makeRequest(e)}>
          <div className="text-center">
            <Header title="Reset Password" description="Enter a new password" />
          </div>
          <div className="mb-4">
            <Input
              label="Password"
              type="password"
              placeholder="Enter password"
              id="password"
              name="password"
              readOnly={loading}
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            />
          </div>
          <div className="mb-8">
            <Input
              label="Re-enter Password"
              type="password"
              placeholder="Enter password"
              id="confirm_password"
              name="confirm_password"
              readOnly={loading}
              value={formData.confirm_password}
              onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })}
            />
          </div>
          <Button
            name="Continue"
            theme="dark"
            disabled={loading || formData.password === '' || formData.confirm_password === ''}
            loading={loading}
            styles="mt-10 w-full"
          />
        </form>
      </div>
    </section>
  );
}
