import React from 'react';
import Logo from '../../assets/icons/Logo';
import imgLogin from '../../assets/images/login-img.svg';

export const LeftAuth = () => {
  return (
    <section className="hidden bg-purple_transparent sticky rounded-2xl py-32 shrink-0 overflow-hidden h-[94vh] max-w-[490px] 2xl:max-w-[700px] top-0 lg:flex lg:flex-col lg:flex-[.6] ml-[24px]">
      <div className="h-full">
        <div className="px-40">
          <Logo />
        </div>
        <div className="flex mt-[6rem] transition ease-in-out duration-1000" id="slider">
          <div className="w-full shrink-0 px-40">
            <h2 className="text-40 font-altone_sb leading-48 mb-2">Effortless admin control, all in one place.</h2>
            <p className="text-16 font-dmsans_r font-normal leading-24 text-grey mb-[2rem]">
              Manage users, track transactions, and optimize operations seamlessly.
            </p>
            <div className="flex w-full items-center">
              <img src={imgLogin} className="flex-1 h-[313px] max-w-[362px]" alt="services" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
