import React, { useState } from 'react';
import Close from 'assets/icons/CloseIcon';
import { formatDate } from 'utils/dateFunctions';
import { UserInitals } from 'utils/ImagePlaceholder';
import { useCustomerState } from 'store/modules/customer';

import CalenderIcon from '../../../assets/icons/CalenderIcon';

const Profile = () => {
  const { selectedCustomer } = useCustomerState();
  const [imagesModal, setImagesModal] = useState(false);

  const toggleImagesModal = () => setImagesModal(!imagesModal);

  return (
    <>
      <div>
        <div className="font-dmsans_m py-32 px-24">
          <p className="text-black-transparent text-16">Personal Details</p>
          <div>
            <div className=" rounded-full bottom-0 left-7 w-[100px] h-[100px] bg-grey-400 flex items-center justify-center my-6">
              {selectedCustomer?.profile_picture && (
                <img src={selectedCustomer?.profile_picture} alt="profile" className="profile_picture" />
              )}
              {!selectedCustomer?.profile_picture && (
                <p className="uppercase text-gray_dark_100 font-dmsans_m text-40">
                  {UserInitals(selectedCustomer?.first_name, selectedCustomer?.last_name)}
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="">
              <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className="text-10 text-grey-600">First Name</p>
                <p className="text-16 text-dark_blue mt-1 capitalize break-words">{selectedCustomer?.first_name}</p>
              </div>
              <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className="text-10 text-grey-600">Last Name</p>
                <p className="text-16 text-dark_blue mt-1 capitalize break-words">{selectedCustomer?.last_name}</p>
              </div>
              <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className="text-10 text-grey-600">Poucher Tag</p>
                <p className="text-16 text-dark_blue mt-1 capitalize break-words">
                  {selectedCustomer?.tag ? '@' + selectedCustomer?.tag : 'N/A'}
                </p>
              </div>
              <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className="text-10 text-grey-600">Gender</p>
                <p className="text-16 text-dark_blue mt-1 capitalize">{selectedCustomer?.gender || 'N/A'}</p>
              </div>
              <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r flex justify-between items-center">
                <div>
                  <p className="text-10 text-grey-600">Date of Birth</p>
                  <p className="text-16 text-dark_blue mt-1">
                    {selectedCustomer?.dob ? formatDate(selectedCustomer?.dob) : 'N/A'}
                  </p>
                </div>
                <div>
                  <CalenderIcon />
                </div>
              </div>
              <div className="mb-4 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                <p className="text-10 text-grey-600">Residential Address</p>
                <p className="text-16 text-dark_blue mt-1 break-words">
                  {selectedCustomer?.address ? `${selectedCustomer?.address + ','}` : ''}{' '}
                  {selectedCustomer?.address ? `${selectedCustomer?.state + ','}` : ''}{' '}
                  {`${selectedCustomer?.country || ''}`}
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col md:flex-row gap-5">
                  <div className="w-full md:w-1/2 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                    <p className="text-10 text-grey-600">BVN Image</p>
                    <p className="text-16 text-dark_blue mt-1 truncate">{selectedCustomer?.bvn_image}</p>
                  </div>
                  <div className="w-full md:w-1/2 bg-smoke px-16 py-2 rounded-lg font-dmsans_r">
                    <p className="text-10 text-grey-600">Selfie Image</p>
                    <p className="text-16 text-dark_blue mt-1 truncate">{selectedCustomer?.selfie_image}</p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={toggleImagesModal}
                  className="font-dmsans_r text-14 text-purple hover:text-purple-300 w-fit mx-auto"
                >
                  View Images
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imagesModal && (
        <div className="fixed inset-0 bg-[#00000060] flex items-center z-20 justify-center">
          <div className="h-4/5 w-[90%] max-h-[455px] max-w-[900px] flex flex-col gap-6 overflow-y-auto">
            <button
              type="button"
              data-testid="closebtn"
              className="cursor-pointer w-[32px] h-[32px] bg-white flex items-center justify-center rounded-full ml-auto"
              onClick={toggleImagesModal}
            >
              <Close color="#000" />
            </button>
            <div className="flex w-full flex-1 flex-col md:flex-row gap-10 overflow-hidden">
              <div className="w-full md:w-1/2 h-full text-white flex flex-col gap-5">
                <p className="font-dmsans_r">BVN Image</p>
                <div className="flex-1 h-full w-full bg-[#00000030] overflow-hidden relative">
                  {selectedCustomer?.bvn_image && (
                    <img
                      src={`data:image/jpeg;base64,${selectedCustomer?.bvn_image}`}
                      alt="profile"
                      className="object-contain absolute h-full w-full"
                    />
                  )}
                </div>
              </div>
              <div className="w-full md:w-1/2 h-full text-white flex flex-col gap-5">
                <p className="font-dmsans_r">Selfie Image</p>
                <div className="flex-1 h-full w-full bg-[#00000030] overflow-hidden relative">
                  {selectedCustomer?.selfie_image && (
                    <img
                      src={`data:image/jpeg;base64,${selectedCustomer?.selfie_image}`}
                      alt="profile"
                      className="object-contain absolute h-full w-full"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
