import React from "react";
import PropTypes from 'prop-types';
import RedirectIfToken from "../../HOC/RedirectIfToken";

// Components
import Logo from "../../assets/icons/Logo";

export default function AuthLayout({ left, center, right }) {
    return(
        <RedirectIfToken>
            <div className="flex lg:p-0 p-6 min-h-[100vh] relative bg-authBg bg-fixed bg-no-repeat bg-right-bottom overflow-hidden items-center">
                { left }
                { center }
                { 
                    right && 
                    <section className="flex-1 flex flex-col md:items-center md:justify-center md:px-48 lg:my-right-layout h-screen">
                        <div className="block lg:hidden">
                            <Logo/>
                        </div>
                        <div className="">
                            { right }
                        </div>
                    </section>
                }
            </div>
        </RedirectIfToken>
    )
}

AuthLayout.propTypes = {
    left: PropTypes.element,
    center: PropTypes.element,
    right: PropTypes.element
};