import { createSlice } from '@reduxjs/toolkit'
import { useAppSelector } from '../../../hooks/useReduxHook'
import { extraReducers } from './reducers'

const initialState = {
  loggedUser: {},
  recoverUser: {},
  isLoggedIn: false,
  loginSuccess: null,
  loading: false,
  error: null,
  token: '',
  resetCode: ''
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  extraReducers,
})

// Selectors,
const selectAuth = (state) => state.auth;

// Action creators are generated for each case reducer function
export const { logout } = auth.actions

//Auth Redux State
export const useAuthState = () => useAppSelector(selectAuth);

export default auth.reducer