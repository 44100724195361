/* eslint-disable max-len */
import React from 'react';

export default function CheckIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7186_56335)">
        <path
          d="M8.5 16C12.911 16 16.5 12.4113 16.5 8C16.5 7.23861 16.3937 6.48828 16.1833 5.76969C16.0796 5.41634 15.7106 5.21338 15.3563 5.31706C15.0026 5.42041 14.8003 5.79069 14.9036 6.14404C15.078 6.74105 15.1667 7.36572 15.1667 8C15.1667 11.6759 12.1759 14.6667 8.5 14.6667C4.82406 14.6667 1.83333 11.6759 1.83333 8C1.83333 4.32406 4.82406 1.33333 8.5 1.33333C9.83594 1.33333 11.1213 1.72396 12.217 2.46273C12.522 2.66862 12.9367 2.58773 13.1423 2.28271C13.348 1.97738 13.2677 1.56299 12.9624 1.35726C11.6457 0.469401 10.1027 0 8.5 0C4.08903 0 0.5 3.5887 0.5 8C0.5 12.4113 4.08903 16 8.5 16Z"
          fill="#D3BCFF"
        />
        <path
          d="M15.3632 1.52722L8.50118 8.38888L6.30587 6.19389C6.04545 5.93363 5.62358 5.93363 5.36316 6.19389C5.10291 6.45431 5.10291 6.87618 5.36316 7.1366L8.02983 9.80326C8.1602 9.93363 8.33044 9.99858 8.50118 9.99858C8.67192 9.99858 8.84216 9.93363 8.97253 9.80326L16.3059 2.46993C16.5661 2.20951 16.5661 1.78764 16.3059 1.52722C16.0455 1.26697 15.6236 1.26697 15.3632 1.52722Z"
          fill="#5034C4"
        />
      </g>
      <defs>
        <clipPath id="clip0_7186_56335">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
