/* eslint-disable max-len */
import React, { useState, useEffect, useCallback } from 'react';
import BackButton from 'components/Button/BackButton';
import CustomButton from 'components/Button/CustomButton';
import Dropdown from 'components/DropDown/DropDown';
import SearchInput from 'components/Input/SearchInput';
import CTable from 'components/Table/Table';
import { RoleListHeader } from 'constants/mocks/roles_permission';
import { HiDotsVertical } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useRolesState } from 'store/modules/admin';
import dayjs from 'dayjs';
import useAdmin from 'hooks/fetch/useAdmin';
import { useDebounce } from 'hooks/useDounce';
import DateFilter from 'components/DropDown/DateFilter';
import usePermissions from 'hooks/usePermissions';
import Title from 'components/Header/Title';
import Switch from 'components/Switch/Switch';
import SecurityModal from './SecurityModal';
import { useSelector } from 'react-redux';

const Security = () => {
  useEffect(() => {
    document.title = 'Security - Pouchers Admin';
  }, []);

  const { loggedUser } = useSelector((state) => state.auth);
  const [tfaOn, setTfaOn] = useState(loggedUser?.two_factor_method !== null);
  const closeTfaOn = () => setTfaOn(true);

  return (
    <div>
      <Title title="Security" variant="mb-4" />
      <div className="p-24 bg-white rounded-lg border border-light-smoke grid gap-1">
        <p className="text-12 leading-4 text-grey">Two-Factor Authentication</p>
        <div className={`relative h-5 w-[50px] rounded-[2px] ${tfaOn ? 'bg-purple' : 'bg-switch-bg'}`}>
          {/* <button type="button" onClick={toggleTFA} className="absolute inset-0 z-10"></button> */}
          <div
            className={`h-[14px] w-[21.5px] bg-transparent absolute top-[3px] rounded-[2px] flex items-center justify-center text-center ${
              tfaOn ? 'right-[25px]' : 'right-[3px]'
            }`}
          >
            <p className={`text-[8px] leading-[8px] ${tfaOn ? 'text-white' : 'text-switch-text'}`}>
              {tfaOn ? 'ON' : 'OFF'}
            </p>
          </div>
          <div
            className={`h-[14px] w-[21.5px] bg-white absolute top-[3px] rounded-[2px] transition-all ${
              tfaOn ? 'left-[25px]' : 'left-[3px]'
            }`}
          ></div>
        </div>
      </div>
      {!tfaOn && <SecurityModal close={closeTfaOn} />}
    </div>
  );
};

export default Security;
