import React, { useRef, useState } from 'react';
import usePIN from 'hooks/usePIN';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import useAuth from 'hooks/fetch/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import useSettings from 'hooks/fetch/useSettings';
import { editAdmin } from 'store/modules/auth/actions';
import useMakeRequest from 'hooks/useMakeRequest';
import { updateLoggedUser } from 'store/modules/auth/reducers';

export default function TfaCodeModal({ close, nextStep, type }) {
  const inputRef = useRef([]);
  const dispatch = useDispatch();
  const { makeRequest } = useMakeRequest();
  const { loggedUser } = useSelector((state) => state.auth);
  const { otp, handleOnChange, handleOnPress, handleOnPaste } = usePIN(6, inputRef);
  const { loading: loadingSettings, set_2fa_email, validate_google_auth_token } = useSettings();

  const makeEmailTfaMode = async () => {
    const res = await set_2fa_email({ otp });
    if (res?.code === 200) {
      nextStep()
      dispatch(updateLoggedUser({ ...loggedUser, two_factor_method: 'EMAIL' }));
    }
  };

  const makeAuthenticatorTfaMode = async () => {
    const res = await validate_google_auth_token({ otp });
    if (res?.code === 200) {
      nextStep()
      dispatch(updateLoggedUser({ ...loggedUser, two_factor_method: 'GOOGLE_AUTHENTICATOR' }));
    }
  };

  const validateOtp = () => {
    if (type === 'email') {
      makeEmailTfaMode();
    } else if (type === 'authenticator') {
      makeAuthenticatorTfaMode();
    }
  };

  return (
    <div className="flex flex-col w-full gap-9">
      <div className="flex flex-col w-full gap-[30px] items-center text-center">
        <p className="text-[24px] leading-8 font-bold text-dark_blue font-dmsans_b">6-digit code</p>
        <div className="flex flex-col gap-5 w-full">
          <div className="flex gap-3">
            {otp.map((currentInput, index) => {
              return (
                <div key={currentInput.key}>
                  <input
                    data-testid={`otp_input-${index}`}
                    id="otp_input"
                    ref={(el) => (inputRef.current[currentInput.key] = el)}
                    onChange={(e) => handleOnChange(e, currentInput)}
                    onKeyDown={(e) => handleOnPress(e, currentInput)}
                    onPaste={(e) => handleOnPaste(e)}
                    type="text"
                    className="w-[42px] h-[42px] md:w-[48px] md:h-[48px] lg:text-18 md:text-16 text-14 text-center md:p-16 p-12 outline-0 font-dmsans_r font-normal rounded-lg  bg-purple_transparent focus:bg-purple_lighter"
                    placeholder=""
                    maxLength={1}
                    readOnly={loadingSettings}
                    value={currentInput.value}
                    autoFocus={currentInput.isFocus}
                  />
                </div>
              );
            })}
          </div>
          <p className="text-14 leading-5 text-grey font-dmsans_r">
            Enter the 6-digit code generated for “Pouchers” from your {type === 'email' ? 'mail' : 'authenticator'}.
          </p>
        </div>
      </div>
      <Button type="button" disabled={loadingSettings} loading={loadingSettings} onClick={validateOtp} styles="flex-1">
        Confirm
      </Button>
    </div>
  );
}

TfaCodeModal.propTypes = {
  close: PropTypes.func,
  type: PropTypes.string,
  nextStep: PropTypes.func,
};
