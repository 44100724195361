import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import React from "react";

export default function RedirectIfToken({ children }) {

  const { isLoggedIn, token, loggedUser } = useSelector(state => state.auth)
  let location = useLocation();

  // Redirect user to security page if user is logged in and does not have 2fa on
  if (token && isLoggedIn && loggedUser && loggedUser?.two_factor_method === null) return <Navigate to="/settings/security" state={{ from: location }} replace />

  // Redirect user to login page if user is not logged in
  if (token && isLoggedIn && loggedUser) return <Navigate to="/home" state={{ from: location }} replace />

  return children
}

RedirectIfToken.propTypes = {
  children: PropTypes.element
};
