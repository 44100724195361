import React from 'react';

export default function EmailIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7186_51446)">
        <path
          d="M5.00065 5H35.0007C35.4427 5 35.8666 5.17559 36.1792 5.48816C36.4917 5.80072 36.6673 6.22464 36.6673 6.66667V33.3333C36.6673 33.7754 36.4917 34.1993 36.1792 34.5118C35.8666 34.8244 35.4427 35 35.0007 35H5.00065C4.55862 35 4.1347 34.8244 3.82214 34.5118C3.50958 34.1993 3.33398 33.7754 3.33398 33.3333V6.66667C3.33398 6.22464 3.50958 5.80072 3.82214 5.48816C4.1347 5.17559 4.55862 5 5.00065 5ZM33.334 12.0633L20.1207 23.8967L6.66732 12.0267V31.6667H33.334V12.0633ZM7.51898 8.33333L20.1023 19.4367L32.504 8.33333H7.51898Z"
          fill="#5034C4"
        />
      </g>
      <defs>
        <clipPath id="clip0_7186_51446">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
