import React, { useState } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import Logo from '../../assets/icons/Logo';
import Header from '../Header/Header';
import { useAuthState } from '../../store/modules/auth';
import useAuth from '../../hooks/fetch/useAuth';
import { useSearchParams } from 'react-router-dom';

export default function CreatePasswordForm() {
  const [searchParams] = useSearchParams();
  const emailAddress = searchParams.get('email');
  //Local State
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: '',
  });
  //Hooks
  const authState = useAuthState();
  const { loading } = authState;
  const { create_password } = useAuth();
  //Handler functions
  const makeRequest = (e) => {
    e.preventDefault();
    create_password(formData, emailAddress);
  };
  return (
    <section className="w-full md:px-40 md:py-48 flex flex-col justify-center items-center h-screen">
      <div className="lg:hidden block mb-48">
        <Logo />
      </div>

      <div className="w-full max-w-md mx-auto mt-10 lg:mt-0">
        <div className="lg:flex hidden mb-48 justify-center">
          <Logo />
        </div>
        <form className="2xl:w-[480px]" onSubmit={(e) => makeRequest(e)}>
          <div className="text-center">
            <Header title="Create Password" description="Enter password to activate account" />
          </div>
          <div className="mb-4">
            <Input
              label="Password"
              type="password"
              placeholder="Enter Password"
              id="password"
              name="password"
              readOnly={loading}
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            />
          </div>
          <div className="mb-8">
            <Input
              label="Re-enter Password"
              type="password"
              placeholder="Enter Password"
              id="confirm_password"
              name="confirm_password"
              readOnly={loading}
              value={formData.confirm_password}
              onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })}
            />
          </div>
          <Button
            name="Continue"
            theme="dark"
            disabled={loading || formData.password !== formData.confirm_password}
            loading={loading}
            styles="mt-10 w-full"
          />
        </form>
      </div>
    </section>
  );
}
