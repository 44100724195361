export const APIData = {
  Airtime: {
    payments: ['Sochitel','Paga', 'Quickteller', 'VAS2Nets'],
    name: 'airtime_utility_provider',
  },
  Data: {
    payments: ['Sochitel','Paga', 'Quickteller', 'VAS2Nets'],
    name: 'data_utility_provider',
  },
  Cable: {
    payments: ['Sochitel','Paga', 'Quickteller', 'VAS2Nets'],
    name: 'cable_utility_provider',
  },
  Electricity: {
    payments: ['Sochitel','Paga', 'Quickteller', 'VAS2Nets'],
    name: 'electricity_utility_provider',
  },
  Betting: {
    payments: ['Sochitel','Paga', 'Quickteller', 'VAS2Nets'],
    name: 'betting_utility_provider',
  },
  Education: {
    payments: ['Sochitel','VAS2Nets'],
    name: 'education_utility_provider',
  },
  Internet: {
    payments: ['Sochitel','Paga', 'Quickteller', 'VAS2Nets'],
    name: 'internet_utility_provider',
  },
};
