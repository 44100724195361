import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import usePIN from 'hooks/usePIN';
import useAuth from 'hooks/fetch/useAuth';
import Button from 'components/Button/Button';

const TfaAuth = ({ close, formData }) => {
  const inputRef = useRef([]);
  const { otp, handleOnChange, handleOnPress, handleOnPaste } = usePIN(6, inputRef);
  const { loading, validate_2fa } = useAuth();

  const validateOtp = async () => {
    const res = await validate_2fa({ ...formData, otp });
    if (res?.code === 200) {
      close();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-30 bg-[#00000030]">
      <div className="flex flex-col w-[95%] max-w-[464px]">
        {/* <div className="w-10 h-10 ml-auto bg-purple_transparent rounded-full flex items-center justify-center">
          <CloseIconWithProps onClick={nextStep} />
        </div> */}
        <div className="flex flex-col w-full items-center max-w-[416px] gap-6 py-48 px-32 bg-white rounded-xl">
          <div className="flex flex-col w-full gap-[30px] items-center text-center">
            <p className="text-[24px] leading-8 font-bold text-dark_blue font-dmsans_b">6-digit code</p>
            <div className="flex flex-col gap-5 w-full">
              <div className="flex gap-3 items-center justify-center">
                {otp.map((currentInput, index) => {
                  return (
                    <div key={currentInput.key}>
                      <input
                        data-testid={`otp_input-${index}`}
                        id="otp_input"
                        ref={(el) => (inputRef.current[currentInput.key] = el)}
                        onChange={(e) => handleOnChange(e, currentInput)}
                        onKeyDown={(e) => handleOnPress(e, currentInput)}
                        onPaste={(e) => handleOnPaste(e)}
                        type="text"
                        className="w-[42px] h-[42px] md:w-[48px] md:h-[48px] lg:text-18 md:text-16 text-14 text-center md:p-16 p-12 outline-0 font-dmsans_r font-normal rounded-lg  bg-purple_transparent focus:bg-purple_lighter"
                        placeholder=""
                        maxLength={1}
                        readOnly={loading}
                        value={currentInput.value}
                        autoFocus={currentInput.isFocus}
                      />
                    </div>
                  );
                })}
              </div>
              <p className="text-14 leading-5 text-grey font-dmsans_r">
                Enter the 6-digit code generated for “Pouchers” from your authenticator or mail inbox.
              </p>
            </div>
          </div>
          <Button
            type="button"
            disabled={loading || otp?.length !== 6}
            loading={loading}
            onClick={validateOtp}
            styles="w-full"
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TfaAuth;

TfaAuth.propTypes = {
  close: PropTypes.func,
  formData: PropTypes.any,
};
