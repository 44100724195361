import React from 'react';

export default function PhoneIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7186_51452)">
        <path
          d="M11.6673 6.66536V33.332H28.334V6.66536H11.6673ZM10.0007 3.33203H30.0007C30.4427 3.33203 30.8666 3.50763 31.1792 3.82019C31.4917 4.13275 31.6673 4.55667 31.6673 4.9987V34.9987C31.6673 35.4407 31.4917 35.8647 31.1792 36.1772C30.8666 36.4898 30.4427 36.6654 30.0007 36.6654H10.0007C9.55862 36.6654 9.1347 36.4898 8.82214 36.1772C8.50958 35.8647 8.33398 35.4407 8.33398 34.9987V4.9987C8.33398 4.55667 8.50958 4.13275 8.82214 3.82019C9.1347 3.50763 9.55862 3.33203 10.0007 3.33203ZM20.0007 28.332C20.4427 28.332 20.8666 28.5076 21.1792 28.8202C21.4917 29.1327 21.6673 29.5567 21.6673 29.9987C21.6673 30.4407 21.4917 30.8647 21.1792 31.1772C20.8666 31.4898 20.4427 31.6654 20.0007 31.6654C19.5586 31.6654 19.1347 31.4898 18.8221 31.1772C18.5096 30.8647 18.334 30.4407 18.334 29.9987C18.334 29.5567 18.5096 29.1327 18.8221 28.8202C19.1347 28.5076 19.5586 28.332 20.0007 28.332Z"
          fill="#5034C4"
        />
      </g>
      <defs>
        <clipPath id="clip0_7186_51452">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
