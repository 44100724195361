import { useState } from 'react';
import {
  getEnvs,
  getProductStatus,
  updateEnv,
  uploadBanner,
  updateProductStatus,
  deletediscount,
  editDiscount,
  getBanners,
  deleteBanner,
  updateBanner,
  getExchangeRate,
  getNotifications,
  createNotification,
  set2faEmail,
  generateGoogleAuthToken,
  validateGoogleAuthToken,
} from '../../store/modules/settings/actions';
import useMakeRequest from '../useMakeRequest';
import { createDiscount, getDiscountByCategory } from 'store/modules/settings/actions';
import { removeSpecificKeys } from 'utils/removeEmptyKeys';
import useTryCatch from 'hooks/useTryCatch';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { notify } from 'store/modules/global';
import useHandleError from 'hooks/useHandleError';

//Handler Functions
export default function useSettings() {
  const dispatch = useAppDispatch();
  const { handleError } = useHandleError();
  const { makeRequest } = useMakeRequest();
  const [loading, setLoading] = useState(false);
  const { tryCatch } = useTryCatch();

  const fetchEnvs = async () => {
    setLoading(true);
    return makeRequest({
      action: getEnvs(),
      alert: false,
    }).finally(() => setLoading(false));
  };

  const fetchExchangeRate = async () => {
    setLoading(true);
    return makeRequest({
      action: getExchangeRate(),
      alert: false,
    }).finally(() => setLoading(false));
  };

  const fetchProductStatus = async () => {
    setLoading(true);
    return makeRequest({
      action: getProductStatus(),
      alert: false,
    }).finally(() => setLoading(false));
  };

  const update_env = async ({ formData, callback }) => {
    setLoading(true);
    return makeRequest({
      action: updateEnv(formData),
      alert: true,
    })
      .then((res) => {
        if ([200, 201].includes(res?.code)) {
          fetchEnvs();
          if (callback) callback();
        }
      })
      .finally(() => setLoading(false));
  };

  const update_product_status = async (formData) => {
    setLoading(true);
    return makeRequest({
      action: updateProductStatus(formData),
      alert: true,
    })
      .then((res) => {
        if ([200, 201].includes(res?.code)) fetchProductStatus();
      })
      .finally(() => setLoading(false));
  };

  const upload_banner = (formData) => {
    setLoading(true);
    return makeRequest({ action: uploadBanner(formData) })
      .then((res) => res)
      .finally(() => setLoading(false));
  };

  const get_banners = () => {
    setLoading(true);
    return makeRequest({ action: getBanners(), alert: false })
      .then((res) => {
        if ([200, 201].includes(res?.code)) return res;
      })
      .finally(() => setLoading(false));
  };

  const update_banner = (payload) => {
    return makeRequest({ action: updateBanner(payload), alert: true });
  };

  const delete_banner = (id) => {
    return makeRequest({ action: deleteBanner(id), alert: true });
  };

  const handleDiscountByCategory = async (id, params) => {
    makeRequest({
      action: getDiscountByCategory({ id, ...params }),
      alert: false,
      to: `/settings/discount-management/${id}`,
    });
  };
  const creatediscount = async (formData, callback) => {
    makeRequest({
      action: createDiscount(formData),
      alert: false,
      callback,
    });
  };
  const editdiscount = async (id, formData, callback) => {
    return tryCatch(async () => {
      const data = removeSpecificKeys(formData, [
        'discount_value',
        'discount_type',
        'start_date',
        'end_date',
        'product_category',
      ]);
      const payload = { id, data };
      makeRequest({
        action: editDiscount(payload),
        alert: false,
        callback,
      });
    });
  };
  const deleteDiscount = async (id, callback) => {
    makeRequest({
      action: deletediscount(id),
      callback,
    });
  };

  const get_notifications = (params) => {
    setLoading(true);
    makeRequest({ action: getNotifications(params), alert: false }).finally(() => setLoading(false));
  };

  const create_notification = async (formData, callback) => {
    setLoading(true);
    makeRequest({
      action: createNotification(formData),
      alert: false,
      callback,
    }).finally(() => setLoading(false));
  };

  const set_2fa_email = async ({ otp: code }) => {
    try {
      let tokenArray = code.map((item) => item.value);
      let otp = tokenArray.join('').toString();
      setLoading(true);
      return makeRequest({ action: set2faEmail({ otp }) }).finally(() => setLoading(false));
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  const generate_google_auth_token = async () => {
    try {
      setLoading(true);
      return makeRequest({ action: generateGoogleAuthToken() }).finally(() => setLoading(false));
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  const validate_google_auth_token = async ({ otp: code }) => {
    try {
      let tokenArray = code.map((item) => item.value);
      let otp = tokenArray.join('').toString();
      setLoading(true);
      return makeRequest({ action: validateGoogleAuthToken({ user2faToken: otp }) }).finally(() => setLoading(false));
    } catch (error) {
      dispatch(notify({ display: true, status: 'error', message: handleError({}) }));
    }
  };

  return {
    fetchEnvs,
    fetchProductStatus,
    update_env,
    creatediscount,
    deleteDiscount,
    handleDiscountByCategory,
    upload_banner,
    delete_banner,
    update_banner,
    editdiscount,
    update_product_status,
    get_banners,
    fetchExchangeRate,
    get_notifications,
    create_notification,
    set_2fa_email,
    generate_google_auth_token,
    validate_google_auth_token,
    loading,
  };
}
