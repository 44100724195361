import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Components
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import Button from 'components/Button/Button';
import CheckIcon from 'assets/icons/CheckIcon';

export default function ManualSetUp({ onClick, twoFactorAuthenticationQrCode }) {
  const { copyToClipboard } = useCopyToClipboard();

  return (
    <section className="transition_left">
      <div>
        <div className="text-center text-purple font-dmsans_b text-24 bg-purple_transparent p-[1rem] mb-[17px] rounded-[300px] overflow-x-auto no-scrollbar">
          {twoFactorAuthenticationQrCode?.two_factor_temp_secret}
        </div>
        <p
          className="text-grey text-16 font-dmsans_b mx-auto mb-[36px] leading-[24px] cursor-pointer w-fit hide_tap"
          onClick={() => copyToClipboard(twoFactorAuthenticationQrCode?.two_factor_temp_secret)}
        >
          Tap to copy
        </p>
      </div>

      <div className="text-grey text-16 font-dmsans_r leading-[24px] mb-[36px] flex flex-col gap-[20px]">
        <div className="flex items-center gap-[15px]">
          <CheckIcon />
          <p className="">Paste the code above in your authenticator app to start generating tokens.</p>
        </div>
        <div className="flex items-center gap-[15px]">
          <CheckIcon />
          <p>Tap completed, when you’ve added the key above successfully.</p>
        </div>
      </div>

      <Button name="Completed" theme="dark" type="button" onClick={onClick} styles="my-4 w-full" />
    </section>
  );
}

ManualSetUp.propTypes = {
  onClick: PropTypes.func,
  twoFactorAuthenticationQrCode: PropTypes.any,
};
