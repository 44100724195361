import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import RequireAuth from '../HOC/RequireAuth';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Login from '../pages/Auth/Login';
import ResetPassword from '../pages/Auth/ResetPassword';
import AppLayout from '../components/Layouts/AppLayout';
import VerifyAccount from '../pages/Auth/VerifyAccount';
import { CreateRole, ManageAdmin } from '../pages/Dashboard/ManageAdmin';
import { GetStarted } from '../pages/Dashboard/GetStarted';
import ChangePassword from '../pages/Auth/CreatePassword';
import ManageCustomers from '../pages/Dashboard/ManageCustomers/ManageCustomers';
import ManageCustomer from '../pages/Dashboard/ManageCustomers/ManageCustomer';
import AdminProfile from '../pages/Dashboard/ManageAdmin/Profile';
import VirtualCardManagement from '../pages/Dashboard/VirtualCardManagement/VirtualCard';
import ViewSingleCard from '../pages/Dashboard/VirtualCardManagement/ViewCardDetails';
import RolesAndPermissions from 'pages/Dashboard/Settings/RolesAndPermissions/RolesAndPermissions';
import Transfers from 'pages/Dashboard/Transfers/Transfer';
import Vouchers from 'pages/Dashboard/Vouchers/Vouchers';
import AuditTrail from 'pages/Dashboard/AuditTrail/AuditTrail';
import SiteSettings from 'pages/Dashboard/Settings/SiteSettings/SiteSettings';
import Notifications from 'pages/Dashboard/Settings/Notifications/Notifications';
import DiscountManagement from 'pages/Dashboard/Settings/DiscountManagement/DiscountManagement';
import CreateDiscount from 'pages/Dashboard/Settings/DiscountManagement/CreateDiscount';
import Payment from 'pages/Dashboard/Payments/Payment';
import DashboardHome from 'pages/Dashboard/Home/Home';
import ScheduleAndAutomated from 'pages/Dashboard/ScheduleAndAutomated/Index';
import Service from 'pages/Dashboard/Settings/DiscountManagement/Index';
import GuestPayment from 'pages/Dashboard/Payments/GuestPayments';
import KycManagement from 'pages/Dashboard/KycMangement/Index';
import TransactionHistory from 'pages/Dashboard/TransactionHistory';
import CustomerSuccessPage from 'pages/Dashboard/ManageCustomers/CustomerSuccessPage';
import MessageList from 'pages/Dashboard/Settings/Notifications/MessageList';
import SendMessage from 'pages/Dashboard/Settings/Notifications/SendMessage';
import CustomerTransactionHistory from 'pages/Dashboard/CustomerTransactionHistory';
import Security from 'pages/Dashboard/Settings/Security/Security';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <RequireAuth>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </RequireAuth>
    ),
    children: [
      {
        path: 'get-started',
        element: <GetStarted />,
      },
      {
        path: 'home',
        element: <DashboardHome />,
      },
      {
        path: 'transfers',
        element: <Transfers />,
      },
      {
        path: 'payments',
        element: <Payment />,
      },
      {
        path: 'guest-payments',
        element: <GuestPayment />,
      },
      {
        path: 'refill-pages',
        element: <>Refill Pages</>,
      },
      {
        path: 'schedule-and-automation',
        element: <ScheduleAndAutomated />,
      },
      {
        path: 'virtual-card-management',
        element: <VirtualCardManagement />,
      },
      {
        path: 'virtual-card-management/:id',
        element: <ViewSingleCard />,
      },
      {
        path: 'vouchers',
        element: <Vouchers />,
      },
      {
        path: 'manage-admin',
        element: <ManageAdmin />,
      },
      {
        path: 'settings/create-role',
        element: <CreateRole />,
      },
      {
        path: 'settings/edit-role/:id',
        element: <CreateRole />,
      },
      {
        path: 'manage-admin/profile',
        element: <AdminProfile />,
      },
      {
        path: 'manage-customer',
        element: <ManageCustomers />,
      },
      {
        path: 'manage-customer/:id',
        element: <ManageCustomer />,
      },
      {
        path: 'manage-customer/success-page',
        element: <CustomerSuccessPage />,
      },
      {
        path: 'kyc-management',
        element: <KycManagement />,
      },
      {
        path: 'api-and-widgets',
        element: <>API & Widgets</>,
      },
      {
        path: 'audit-logs',
        element: <AuditTrail />,
      },
      {
        path: 'transaction-history',
        element: <TransactionHistory />,
      },
      {
        path: 'customer-transactions',
        element: <CustomerTransactionHistory />,
      },
      {
        path: 'settings/site-settings',
        element: <SiteSettings />,
      },
      {
        path: 'settings/notifications',
        element: <Notifications />,
      },
      {
        path: 'settings/notifications/messaging',
        element: <MessageList />,
      },
      {
        path: 'settings/notifications/send-message',
        element: <SendMessage />,
      },
      {
        path: 'settings/discount-management',
        element: <DiscountManagement />,
      },
      {
        path: 'settings/discount-management/:id',
        element: <Service />,
      },
      {
        path: '/settings/discount-management/:id/:type',
        element: <CreateDiscount />,
      },
      {
        path: 'settings/roles-and-permissions',
        element: <RolesAndPermissions />,
      },
      {
        path: 'settings/security',
        element: <Security />,
      },
      {
        path: 'contact-support',
        element: <>Contact Support</>,
      },
      {
        path: 'terms-of-use',
        element: <>Terms of Use</>,
      },
      {
        path: 'privacy-policy',
        element: <>Privacy Policy</>,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: 'verify-account',
    element: <VerifyAccount />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
  },
  {
    path: 'admin/create-password',
    element: <ChangePassword />,
  },
]);

export { router };
