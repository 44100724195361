import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  login,
  refreshToken,
  forgotPassword,
  validatePasswordResetToken,
  resetPassword,
  inviteAdmin,
  editAdmin,
  createPassword,
  assignRole,
  editRoleDetails,
  changePassword,
  validate2fa,
} from './actions';

const setUser = (state, action) => {
  state.loading = false;
  state.isLoggedIn = true;
  if (action.payload.data) {
    state.token = action.payload.data?.token;
    state.loggedUser = action.payload.data;
    state.loginSuccess = action.payload.status;
  }
};

export const updateLoggedUser = createAsyncThunk('auth/updateLoggedUser', async (newUserData) => {
  return newUserData;
});

const extraReducers = {
  [login.fulfilled]: (state, action) => {
    setUser(state, action);
  },
  [validate2fa.fulfilled]: (state, action) => {
    setUser(state, action);
  },
  [updateLoggedUser.fulfilled]: (state, action) => {
    state.loggedUser = action.payload; // Update loggedUser state
  },
  [refreshToken.fulfilled]: (state, action) => {
    setUser(state, action);
  },
  [inviteAdmin.fulfilled]: (state) => {
    state.loading = false;
  },
  [editAdmin.fulfilled]: (state) => {
    state.loading = false;
  },
  [refreshToken.fulfilled]: (state, action) => {
    state.loading = false;
    if (action.payload.data) {
      state.token = action.payload.data?.token;
      state.loggedUser = action.payload.data;
      state.loginSuccess = action.payload.status;
      state.isLoggedIn = action.payload.status === 'success' ? true : false;
    }
  },
  [forgotPassword.fulfilled]: (state, action) => {
    state.loading = false;
    if (action.payload.data) state.recoverUser = action.payload.data;
  },
  [validatePasswordResetToken.fulfilled]: (state, action) => {
    state.loading = false;
    if (action.payload?.data) {
      state.reset_code = action.meta.arg.reset_code;
      state.recoverUser = action.payload.data;
    }
  },
  [resetPassword.fulfilled]: (state) => {
    state.loading = false;
    state.loggedUser = {};
    state.reset_code = '';
  },
  [changePassword.fulfilled]: (state) => {
    state.loading = false;
  },
  [createPassword.fulfilled]: (state) => {
    state.loading = false;
  },
  [assignRole.fulfilled]: (state) => {
    state.loading = false;
  },
  [editRoleDetails.fulfilled]: (state) => {
    state.loading = false;
  },
  [editAdmin.fulfilled]: (state, action) => {
    state.loading = false;
    state.loggedUser = { ...state.loggedUser, ...action.payload.data };
  },
};

export { extraReducers };
